<template>
  <div class="inner_container">
    <div class="col-md-9 mx-auto">
      <table class="table table-blue table-striped">
        <thead class="tht-blue">
          <tr >
            <th class="text-center"></th>
            <th class="text-center">Classe 1</th>
            <th class="text-center">Classe 2</th>
            <th class="text-center">Classe 3</th>
            <th class="text-center">Classe 4</th>

          </tr>
        </thead>
        <tbody v-if="donnees.entete !== undefined">
          <tr class="text-center bg-secondary text-light">
            <th scope="row"></th>
            <td>{{ espacingNumber(donnees.entete.classe_1 )}}</td>
            <td>{{ espacingNumber(donnees.entete.classe_2) }}</td>
            <td>{{ espacingNumber(donnees.entete.classe_3) }}</td>
            <td>{{ espacingNumber(donnees.entete.classe_4 )}}</td>

          </tr>
          <tr >
            <td colspan="5"></td>
          </tr>
          <tr class="text-center"
              v-for="(objet,key) in paginationCorps"
              :key="key">
            <th scope="row">{{ objet.annee }}</th>
            <td>{{ fixeDecimalTwo(objet.classe_1 ).toLocaleString()}}</td>
            <td>{{ fixeDecimalTwo(objet.classe_2) }}</td>
            <td>{{ fixeDecimalTwo(objet.classe_3 )}}</td>
            <td>{{ fixeDecimalTwo(objet.classe_4) }}</td>

          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <th colspan="5"
                class="text-center"> Aucune donnée trouvée !!</th>
          </tr>
        </tbody>
      </table>
      <div class="col-md-1 mx-auto text-center"
           v-if="pageCount !==0">
        <button @click="previewPage"
                :disabled="pageNumber == 0"
                class="badge badge-pill badge-info">
          &#60;
        </button>
        <button @click="nextPage"
                class="badge badge-pill badge-info">
          &#62;
        </button>
        <span>page {{pageNumber +1}}/{{pageCount}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/assets/js/utils"

export default {
  name: "RevenusAnnuel",
  props:['year',"revenuAnnuelle"],
  data:()=>({
    donnees:{
      entete:{},
      corps:{}
    },
    longueurCorps:0,
    pageNumber:0,
    size:10
  }),
  watch:{
    revenuAnnuelle(){
      if(this.revenuAnnuelle){
        this.donnees.entete=this.revenuAnnuelle[0]
        this.donnees.corps=this.revenuAnnuelle.slice(1)
      }
      this.longueurCorps = this.donnees.corps.length


    }
  },
  computed:{
    pageCount(){
      var l = this.donnees.corps.length
      var s = this.size
      return Math.ceil(l/s)
    },
    paginationCorps(){
      const start = this.pageNumber * this.size,
            end = start + this.size
      return this.donnees.corps.slice(start,end)
    }
  },
  created() {
    if(this.revenuAnnuelle){
      this.donnees.entete=this.revenuAnnuelle[0]
      this.donnees.corps=this.revenuAnnuelle.slice(1)
    }
    this.longueurCorps = this.donnees.corps.length
  },
  methods:{
    fixeDecimalTwo(nombre) {
      return utils.fixeDecimalTwo(nombre)
    },
    espacingNumber(nombre) {
      return utils.espacingNumber(nombre)
    },
    nextPage(){
      if(this.pageNumber+1 < this.pageCount){
        this.pageNumber++
      }
    },
    previewPage(){
      this.pageNumber--
    }
  }
}
</script>

<style scoped>

</style>