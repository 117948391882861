<template>
  <div class="inner_container">
    <div class="col-md-9 mx-auto">
      <table class="table table-blue table-bordered">
        <thead class="tht-blue">
          <tr>
            <th class="text-center"
                scope="col">Tarif classe 1</th>
            <th class="text-center"
                scope="col">Classe 1</th>
            <th class="text-center"
                scope="col">Classe 2</th>
            <th class="text-center"
                scope="col">Classe 3</th>
            <th class="text-center"
                scope="col">Classe 4</th>
          </tr>
        </thead>
        <tbody v-if="longueurTrafic !==0">
          <tr class="text-center"
              v-for="(singletrafic,key) in traficPaginantion"
              :key="key">
            <th scope="row">{{espacingNumber(singletrafic.tarif_classe_1)}}</th>
            <td>{{espacingNumber(singletrafic.classe_1)}}</td>
            <td>{{espacingNumber(singletrafic.classe_2)}}</td>
            <td>{{espacingNumber(singletrafic.classe_3)}}</td>
            <td>{{espacingNumber(singletrafic.classe_4)}}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <th colspan="5"
                class="text-center">Aucune donnée trouvée !!</th>
          </tr>
        </tbody>
      </table>
      <div class="col-md-1 mx-auto text-center"
           v-if="pageCount !== 0">
        <button @click="previewPage"
                :disabled="pageNumber == 0"
                class="badge badge-pill badge-info">
          &#60;
        </button>
        <button @click="nextPage"
                class="badge badge-pill badge-info">
          &#62;
        </button>
        <span>page {{pageNumber +1}}/{{pageCount}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/assets/js/utils"

export default {
  name: "TraficAnnee",
  props:['year','trafic'],
  data:()=>({
    longueurTrafic:0,
    pageNumber:0,
    size:10
  }),
  watch:{
    trafic(){
      if(this.trafic){
        this.longueurTrafic=this.trafic.length

      }
    }
  },
  created() {
    if(this.trafic){
      this.longueurTrafic=this.trafic.length
    }

  },
  computed:{
    pageCount(){
      var l = this.trafic.length
      var s= this.size
      return Math.ceil(l/s)
    },
    traficPaginantion(){
      const start = this.pageNumber * this.size,
            end = start + this.size
      return this.trafic.slice(start,end)
      
    }
  },
  methods:{
    espacingNumber(nombre) {
      return utils.espacingNumber(nombre)
    },
    nextPage(){
      if(this.pageNumber +1 < this.pageCount ){
        this.pageNumber++
      }

    },
    previewPage(){
      this.pageNumber--
    }
  }
}
</script>

<style scoped>

</style>