<template>
  <div class="main_container">
    <!-- <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title text-uppercase">
            Projection des tarifs
          </div>
        </div>
      </div>
    </div> -->

    <ul class="nav nav-tabs"
        id="myTab"
        role="tablist">

      <li class="nav-item">
        <a class="nav-link active"
           id="decompte-tab"
           data-toggle="tab"
           href="#anneeChoisi"
           role="tab"
           aria-controls="profile"
           aria-selected="false">Trafic moyen </a>
      </li>

      <li class="nav-item">
        <a class="nav-link"
           id="revenusannuels-tab"
           data-toggle="tab"
           href="#revenusannuels"
           role="tab"
           aria-controls="profile"
           aria-selected="false">Revenus Annuels</a>
      </li>
    </ul>
    <!--les dives -->
    <div class="tab-content mt-4"
         id="myTabContent">
      <!-- vue -->

      <div class="tab-pane fade show active"
           id="anneeChoisi"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <TraficAnnee :year="2020"
                     :trafic="trafic"/>
      </div>


      <div class="tab-pane fade "
           id="revenusannuels"
           role="tabpanel"
           aria-labelledby="profile-tab">
        <RevenusAnnuel :revenuAnnuelle="revenuAnnuelle"/>
      </div>

    </div>
  </div>
</template>

<script>
import TraficAnnee from "@/components/Analyses/businessPlan/TraficAnnee"
import RevenusAnnuel from "@/components/Analyses/businessPlan/RevenusAnnuel"
import {mapActions, mapGetters} from "vuex"
export default {
  name: "ProjectionTarif",
  props:["projectionDesTarifs"],
  components:{
    TraficAnnee,
    RevenusAnnuel
  },
  data:()=>({
    trafic:[],
    revenuAnnuelle:[]

  }),
  watch:{
    projectionDesTarifs(){
      if(this.projectionDesTarifs){
        this.trafic = this.projectionDesTarifs[0].trafic_moyen
        this.revenuAnnuelle = this.projectionDesTarifs[0].Revenu_annel
      }
    }
  },

  computed: {
    ...mapGetters([ "repBp"])
  },
  methods:{
    ...mapActions(["postBusinessPlan"]),
  }
}
</script>

<style scoped>

</style>